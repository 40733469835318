import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customerCount", "customerCheckbox"]

  connect() {
    const dialog = this.element.querySelector("dialog")
    dialog.addEventListener("close", this.updateCount);
    this.updateCount()
  }

  disconnect() {
    const dialog = this.element.querySelector("dialog")
    dialog.removeEventListener("close", this.updateCount);
  }

  updateCount = () => {
    const count = this.customerCheckboxTargets.filter(checkbox => checkbox.checked).length
    this.customerCountTarget.innerText = count === 1 ? "1 customer" : `${count} customers`
  }
}
